import { useState } from 'react';

export const useGlitchEffect = (originalText, duration = 700) => {
  const [text, setText] = useState(originalText);
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
  let timeouts = [];

  const clearGlitchTimeouts = () => {
    timeouts.forEach(clearTimeout);
    timeouts = [];
  };

  const generateGlitchText = () => {
    return Array.from({ length: originalText.length })
      .map(() => characters[Math.floor(Math.random() * characters.length)])
      .join('');
  };

  const glitch = (original, glitched, index = 0) => {
    if (index < original.length) {
      const timeoutId = setTimeout(() => {
        setText(glitched.substring(0, index) + original.substring(index));
        if (index === original.length - 1) {
          // After the last character has glitched, start the smooth transition back to the original text
          clearGlitchTimeouts();
          setTimeout(() => setText(original), 50);
        } else {
          glitch(original, generateGlitchText(), index + 1);
        }
      }, Math.random() * (duration / original.length));

      timeouts.push(timeoutId);
    }
  };

  const onMouseEnter = () => {
    clearGlitchTimeouts();
    glitch(originalText, generateGlitchText());
  };

  const onMouseLeave = () => {
    clearGlitchTimeouts();
    setText(originalText);
  };

  return [text, onMouseEnter, onMouseLeave];
};
