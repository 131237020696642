import { useState, useEffect, useRef, useCallback } from 'react';

export const useDynamicGlitchEffect = (originalText, duration = 700, repeatInterval = 2000) => {
  const [text, setText] = useState(originalText);
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
  const glitchTimeouts = useRef([]);
  const repeatTimeout = useRef();

  const clearGlitchTimeouts = useCallback(() => {
    glitchTimeouts.current.forEach(clearTimeout);
    glitchTimeouts.current = [];
    clearTimeout(repeatTimeout.current);
  }, []);

  const generateGlitchText = useCallback((original, glitchAmount, glitchAll = false) => {
    let glitchedText = original.split('');
    if (glitchAll) {
      // Glitch all characters
      return glitchedText.map(() => characters[Math.floor(Math.random() * characters.length)]).join('');
    } else {
      // Glitch only a subset of characters
      for (let i = 0; i < glitchAmount; i++) {
        const charIndex = Math.floor(Math.random() * glitchedText.length);
        glitchedText[charIndex] = characters[Math.floor(Math.random() * characters.length)];
      }
      return glitchedText.join('');
    }
  }, [characters]);

  const glitch = useCallback((original, index = 0, maxSteps = 5) => {
    if (index <= maxSteps) {
      const glitchAll = Math.random() < 0.2; // 20% chance to glitch all characters
      const glitchAmount = glitchAll ? original.length : Math.floor(Math.random() * original.length / 2) + 1;
      const glitchedText = generateGlitchText(original, glitchAmount, glitchAll);

      const timeoutId = setTimeout(() => {
        setText(index === maxSteps ? original : glitchedText);
        glitch(original, index + 1, maxSteps);
      }, Math.random() * (duration / maxSteps));

      glitchTimeouts.current.push(timeoutId);
    }
  }, [duration, generateGlitchText]);

  useEffect(() => {
    const startGlitch = () => {
      glitch(originalText);
      repeatTimeout.current = setTimeout(startGlitch, repeatInterval + duration);
    };

    startGlitch();

    return clearGlitchTimeouts;
  // Include dependencies here
  }, [originalText, duration, repeatInterval, clearGlitchTimeouts, glitch]);

  return text;
};
