import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure this points to your CSS file
import { useDynamicGlitchEffect } from './dynamicGlitchEffect'; 
import { useGlitchEffect } from './glitchHover';
import { ethers } from 'ethers';

function App() {
  const [step, setStep] = useState(1);
  const [walletAddress, setWalletAddress] = useState('');
  const headlineText = useDynamicGlitchEffect("Welcome to our app", 700);
  const [glitchText, onMouseEnter, onMouseLeave] = useGlitchEffect("👉 Next Step", 700);
  const [showAuthKeyPopup, setShowAuthKeyPopup] = useState(false);
  const [authKey, setAuthKey] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const validKeys = ["testkey"];
  const [isAuthKeyValid, setIsAuthKeyValid] = useState(false); // New state to track auth key validity
  const [signature, setSignature] = useState('');
  const [showFullSignature, setShowFullSignature] = useState(false);


  // Function to connect to the user's Ethereum wallet
  const connectWallet = async () => {
    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      // Create a Web3 provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Use the provider to interact with the blockchain
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      console.log('Connected account:', address);
      // Set the connected wallet address and proceed to the next step
      setWalletAddress(address);
      setStep(3); // Modify the step state to move forward in the app
    } catch (error) {
      console.error('Error connecting to the wallet:', error);
    }
  };


  const verifyAuthKey = async (key) => {
    setFeedbackMessage('Checking auth key...');
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating an async operation
    if (validKeys.includes(key)) {
      setFeedbackMessage('Auth key verified.');
      setIsAuthKeyValid(true); // Set auth key as valid
    } else {
      setFeedbackMessage('Invalid auth key. Please try again.');
      setIsAuthKeyValid(false); // Reset validity on failure
    }
  };

  const handleRegister = () => {
    setShowAuthKeyPopup(true);
  };
  
// Function to proceed with the registration after auth key submission
const proceedWithRegistration = async () => {
  setFeedbackMessage('Checking auth key...');
  // Ensure the message is displayed for at least 1 second for better user experience
  const isValid = await new Promise((resolve) => {
    setTimeout(async () => {
      if (validKeys.includes(authKey)) {
        resolve(true);
      } else {
        resolve(false);
      }
    }, 1000); // Adjust this duration as needed
  });

  if (!isValid) {
    setFeedbackMessage('Invalid auth key. Please try again.');
    setIsAuthKeyValid(false);
    return;
  }

  // Update state and UI to reflect the successful verification
  setIsAuthKeyValid(true);
  setFeedbackMessage('Auth key verified. Please sign the message to validate your auth key and link it with your wallet.');
};


const signMessage = async () => {
  if (!walletAddress || !isAuthKeyValid) {
    setFeedbackMessage('Please connect your wallet and verify your auth key first.');
    return;
  }

  const message = `I am the owner of this wallet and my auth key is: ${authKey}`;
  try {
    const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
    const signatureResult = await signer.signMessage(message);
    console.log('Signature:', signatureResult);
    setSignature(signatureResult); // Save the signature hash to state
    setFeedbackMessage('Message signed successfully. Your auth key is now linked with your wallet.');
  } catch (error) {
    console.error('Error signing message:', error);
    setFeedbackMessage('Failed to sign the message. Please try again.');
  }
};


const handleNextStep = () => {
  // Only enforce wallet connection when trying to move beyond Step 2
  if (step === 2 && !walletAddress) {
    // Wallet not connected, show an error message
    alert("Please connect your wallet to proceed.");
    return;
  }

  // If the wallet is connected (or if the current step does not require a wallet connection), proceed to the next step
  setStep(step + 1);
};


  // Function to render step-specific content
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <p className="mb-4 text-lg">Create your account via our Telegram bot. It's quick and easy!</p>
            <a
              href="https://t.me/your_bot" // Replace with your actual Telegram bot link
              target="_blank" rel="noreferrer"
              className="styled-button"
            >
              1️⃣ Create Account on Telegram
            </a>
          </>
        );
      case 2:
        return (
          <>
            <p className="mb-4 text-lg">Connect your wallet to start using our services securely.</p>
            <button
              className="styled-button"
              onClick={connectWallet}
            >
              2️⃣ Connect Wallet
            </button>
          </>
        );
      case 3:
        return (
          <>
            <p className="mb-4 text-lg">Register to finalize your setup and join our community.</p>
            {walletAddress && (
  <div className="wallet-address">
    <div>DAPP CONNECTED WITH:</div>
    <div>{walletAddress}</div>
  </div>
)}
            <button
              className="styled-button"
              onClick={handleRegister}
            >
              3️⃣ Register
            </button>
          </>
        );
      default:
        return null;
    }
  };

  // Function to go to the previous step
  const handleGoBack = () => {
    if (step > 1) {
      setStep(prevStep => prevStep - 1);
    }
  };

  return (
    <div className="min-h-screen bg-base-dark text-white flex flex-col items-center justify-center">
      <h1 className="headline">{headlineText}</h1>
      <p>Complete three simple steps and join our closed beta.</p>
      <a href="https://docs.example.com" target="_blank" rel="noreferrer" className="docs-link">
        Click here to learn more in our docs.
      </a>
      {renderStepContent()}
      {step > 1 && (
        <button 
          className="back-step-button" 
          onClick={handleGoBack}
        >
          👈 Go Back
        </button>
      )}
     {step < 3 && (
  <button 
    className="next-step-button" 
    onClick={handleNextStep} // Updated to use handleNextStep function
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {glitchText}
  </button>
)}

  {showAuthKeyPopup && (
        <div className="popup-container">
          <div className="popup-content">
          <button onClick={() => setShowAuthKeyPopup(false)} className="close-popup-button">&times;</button>

            {feedbackMessage && (
              <div className="feedback-message">
                {feedbackMessage}
                {signature && (
                  <div>
                    Signature hash: 
                    <div className="signature-hash-container">
                      {showFullSignature ? signature : `${signature.substring(0, 6)}...`}
                    </div>
                    <button onClick={() => setShowFullSignature(!showFullSignature)} className="show-more-button">
                      {showFullSignature ? "Show Less" : "Show More"}
                    </button>
                  </div>
                )}
              </div>
            )}
            {!isAuthKeyValid && (
              <>
                <p>Please provide your auth key:</p>
                <input
                  type="text"
                  value={authKey}
                  onChange={(e) => setAuthKey(e.target.value)}
                  placeholder="Enter auth key"
                  className="auth-key-input"
                />
                <button onClick={proceedWithRegistration} className="continue-button">Continue</button>
              </>
            )}
            {isAuthKeyValid && (
              <>
                <div className="auth-info">
                  <p>Wallet Address: {walletAddress}</p>
                  <p>Auth Key: {authKey}</p>
                </div>
                <button onClick={signMessage} className="sign-message-button">Sign Message (Gas Free)</button>
              </>
            )}
          </div>
        </div>
      )}
          </div>
    
  );
};

export default App;